import { defineComponent, inject } from 'vue';
import Dialog from '@/components/share/Dialog.vue';
import Popup from '@/components/share/Popup.vue';
import Page from '@/components/share/Page.vue';
export default defineComponent({
  name: 'MemberBenefitsPopup',
  components: {
    DialogComponent: Dialog,
    Page: Page,
    Popup: Popup
  },
  props: ['isOpenPopup', 'onClosePopup', 'slot'],
  setup: function setup() {
    var t = inject('t');
    var isMobile = inject('isMobile');
    return {
      t: t,
      isMobile: isMobile
    };
  }
});