import { computed, defineComponent, ref } from 'vue';
import { useAuthStore } from '@/hook/useAuthStore';
import { useStore } from 'vuex';
import { numberWithCommas } from '@/utils/numberWithCommas';
export default defineComponent({
  name: 'PointHistory',
  setup: function setup() {
    var store = useStore();
    var userInfo = useAuthStore().userInfo;
    var levelInfo = computed(function () {
      return store.state.user.levelInfo;
    });
    var pointHistory = computed(function () {
      return store.getters['point/getPointHistory'];
    });
    var list = ref(true);

    var expiredDateTrans = function expiredDateTrans(date) {
      return date === '9999/12/30 23:59' ? '無限期' : date;
    };

    return {
      levelInfo: levelInfo,
      userInfo: userInfo,
      list: list,
      pointHistory: pointHistory,
      numberWithCommas: numberWithCommas,
      expiredDateTrans: expiredDateTrans
    };
  }
});