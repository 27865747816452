import "core-js/modules/es.object.keys.js";
import { defineComponent, inject, ref, onMounted, computed } from 'vue';
import MemberCenterLayout from '@/components/personal/MemberCenterLayout.vue';
import Transaction from '@/components/personal/Transaction.vue';
import PointPopup from '@/components/personal/PointPopup.vue';
import { useStore } from 'vuex';
import { useAuthStore } from '@/hook/useAuthStore';
import { numberWithCommas } from '@/utils/numberWithCommas';
import SvgIcon from '@/components/share/SvgIcon.vue';
export default defineComponent({
  name: 'Wallet',
  components: {
    MemberCenterLayout: MemberCenterLayout,
    Transaction: Transaction,
    PointPopup: PointPopup,
    SvgIcon: SvgIcon
  },
  setup: function setup() {
    var t = inject('t');
    var isMobile = inject('isMobile');
    var isOpenPointPopup = ref(false);
    var store = useStore();
    var userInfo = useAuthStore().userInfo;
    var getExpiredPoints = computed(function () {
      return store.getters['point/calculateExpiredPoint'];
    });
    var expiredPoints = computed(function () {
      return Object.keys(getExpiredPoints.value);
    });
    onMounted(function () {
      store.dispatch('point/userPoint');
    });
    var point = computed(function () {
      return store.state.point.point;
    });

    var handlePointPopup = function handlePointPopup(isOpen) {
      isOpenPointPopup.value = isOpen;
    };

    return {
      t: t,
      isMobile: isMobile,
      isOpenPointPopup: isOpenPointPopup,
      handlePointPopup: handlePointPopup,
      point: point,
      userInfo: userInfo,
      expiredPoints: expiredPoints,
      getExpiredPoints: getExpiredPoints,
      numberWithCommas: numberWithCommas
    };
  }
});